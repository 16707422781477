import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { createClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';

const supabaseUrl = 'https://nrnxmczlxdtieqkamqcu.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5ybnhtY3pseGR0aWVxa2FtcWN1Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyMjg1MDQ4OSwiZXhwIjoyMDM4NDI2NDg5fQ.ulMNy607zHSdzUvkF0vvyvcBElKdFj44-IQnmvCdWzo';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

function GeneralSettings() {
  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
    email: "",
    website: "",
    tax_id: "",
    currency: "",
    bank_name: "",
    account_name: "",
    account_number: "",
    routing_number: "",
    swift_code: "",
    logo_url: "",
  });
  const [tempLogo, setTempLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const fetchCompanyDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('company_info')
        .select('*')
        .single();

      if (error) throw error;
      if (data) {
        setCompanyDetails(data);
        if (data.logo_url) {
          setLogoUrl(data.logo_url);
          console.log('Fetched logo URL:', data.logo_url);
        }
      }
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  };

  const getSignedUrl = async (filePath) => {
    try {
      const { data, error } = await supabase
        .storage
        .from('paracloud')
        .createSignedUrl(filePath, 3600 * 24 * 365); // URL valid for 1 year

      if (error) throw error;
      console.log('Generated signed URL:', data.signedUrl);
      return data.signedUrl;
    } catch (error) {
      console.error('Error generating signed URL:', error);
      return null;
    }
  };

  const handleCompanyDetailChange = (e) => {
    setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileExt = file.name.split('.').pop();
      const fileName = `${uuidv4()}.${fileExt}`;
      const filePath = `company_images/${fileName}`;

      setTempLogo({
        file,
        path: filePath,
      });

      const localUrl = URL.createObjectURL(file);
      setLogoUrl(localUrl);
      console.log('Temporary local URL for preview:', localUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let logoUrl = companyDetails.logo_url;

      if (tempLogo) {
        const { data, error: uploadError } = await supabase.storage
          .from('paracloud')
          .upload(tempLogo.path, tempLogo.file);

        if (uploadError) throw uploadError;

        if (data) {
          logoUrl = await getSignedUrl(tempLogo.path);
          console.log('New file signed URL:', logoUrl);
        }
      }

      const { error } = await supabase
        .from('company_info')
        .upsert({ ...companyDetails, logo_url: logoUrl });

      if (error) throw error;

      alert('Company details updated successfully!');
      setTempLogo(null);
      fetchCompanyDetails();
    } catch (error) {
      console.error('Error updating company details:', error);
      alert(`Failed to update company details: ${error.message}`);
    }
  };

  const handleCancel = () => {
    fetchCompanyDetails();
    setTempLogo(null);
  };

  return (
    <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Company Name"
                          name="company_name"
                          value={companyDetails.company_name}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Address"
                          name="address"
                          value={companyDetails.address}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                          multiline
                          rows={3}
                        />
                        <TextField
                          fullWidth
                          label="City"
                          name="city"
                          value={companyDetails.city}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="State"
                          name="state"
                          value={companyDetails.state}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Zip Code"
                          name="zip_code"
                          value={companyDetails.zip_code}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Country"
                          name="country"
                          value={companyDetails.country}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          name="phone"
                          value={companyDetails.phone}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          value={companyDetails.email}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Website"
                          name="website"
                          value={companyDetails.website}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Tax ID"
                          name="tax_id"
                          value={companyDetails.tax_id}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Default Currency"
                          name="currency"
                          value={companyDetails.currency}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mt={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Bank Name"
                          name="bank_name"
                          value={companyDetails.bank_name}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Account Name"
                          name="account_name"
                          value={companyDetails.account_name}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Account Number"
                          name="account_number"
                          value={companyDetails.account_number}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Routing Number"
                          name="routing_number"
                          value={companyDetails.routing_number}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="SWIFT Code"
                          name="swift_code"
                          value={companyDetails.swift_code}
                          onChange={handleCompanyDetailChange}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleLogoUpload}
                      />
                      <label htmlFor="raised-button-file">
                        <MDButton variant="contained" component="span" color="primary">
                          Upload Logo
                        </MDButton>
                      </label>
                      {logoUrl && (
                        <Box mt={2}>
                          <img 
                            src={logoUrl} 
                            alt="Company Logo" 
                            style={{ maxWidth: '200px' }} 
                          />
                        </Box>
                      )}
                    </Grid>
                    <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
                      <MDButton onClick={handleCancel} color="secondary" sx={{ marginRight: 2 }}>
                        Cancel
                      </MDButton>
                      <MDButton type="submit" variant="gradient" color="info">
                        Save Changes
                      </MDButton>
                    </MDBox>
                  </form>
                );
            }

export default GeneralSettings;