import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/Auth';
import {
  Card,
  Grid,
  Divider,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ThemedCustomStepper from 'components/CustomStepper';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Activities from 'layouts/dashboard/components/Activities';
import InlineEditField from 'components/CustonEditableInlineField/CustomEditableInlineField';
import ConvertLeadModal from 'components/CustomLeadForms/CustomLeadConvert';
import DeleteButton from 'components/CustomDeleteIcon';

import { Edit, Save, SquareX, Copy } from 'lucide-react';

function LeadDetail() {
  const { user, axiosInstance } = useAuth();
  const { id } = useParams();
  console.log(`Lead ID: ${id}`);
  
  const navigate = useNavigate();

  const location = useLocation();
  const leadData = location.state?.leadData;
  const [lead, setLead] = useState(leadData || {});

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConvertModalOpen, setIsConvertModalOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNoteLoading, setIsNoteLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [deleteRelated, setDeleteRelated] = useState(false);
  const [newActivity, setNewActivity] = useState({
    lead_id: id,
    type: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
  });
  const [opportunityData, setOpportunityData] = useState({
    name: '',
    value: '',
    stage: 'Qualification',
    probability: 0,
    close_date: new Date().toISOString().split('T')[0],
    user_id: user.id,
    lead_id: id,
  });
  const [activeStep, setActiveStep] = useState(0);
  const leadStatus = [
    'New',
    'In Progress',
    'Qualified',
  ];
  const steps = ['New', 'In Progress', 'Qualified'];
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const isLeadLocked = lead.lead_status === "Qualified" || lead.lead_status === "Disqualified";
  const [isQualificationModalOpen, setIsQualificationModalOpen] = useState(false);
  const [qualificationData, setQualificationData] = useState({
    value: '',
    stage: 'Qualification',
    probability: 0,
  });

  useEffect(() => {
    fetchLeadData();
    fetchActivities();
    fetchProducts();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric'
    });
    const formattedTime = date.toLocaleTimeString('en-US', { 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true
    });
    return `${formattedDate}, ${formattedTime}`;
  };


  // const handleEditToggle = () => {
  //   if (isEditing) {
  //     handleSaveChanges();
  //   }
  //   setIsEditing(!isEditing);
  // };

  const handleEditToggle = () => {
    if (isLeadLocked) {
      setSnackbar({
        open: true,
        message: 'This lead is locked and cannot be edited.',
        severity: 'warning',
      });
      return;
    }
    if (isEditing) {
      handleSaveChanges();
    }
    setIsEditing(!isEditing);
  };

  const handleCloneLead = async () => {
    try {
      const clonedLead = { ...lead };
      delete clonedLead.id;
      clonedLead.lead_status = "New";
      clonedLead.created_at = new Date().toISOString();
      clonedLead.updated_at = new Date().toISOString();

      const response = await axiosInstance.post('/leads/', clonedLead);
      setSnackbar({
        open: true,
        message: 'Lead cloned successfully!',
        severity: 'success',
      });
      navigate(`/lead/detail/${response.data.id}`);
    } catch (error) {
      console.error('Error cloning lead:', error);
      setSnackbar({
        open: true,
        message: 'Error cloning lead. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleQualificationDataChange = (e) => {
    setQualificationData({
      ...qualificationData,
      [e.target.name]: e.target.value,
    });
  };

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get('/products/');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleProductChange = (event, newValue) => {
    setSelectedProducts(newValue);
    handleFieldUpdate('interested_products', newValue.map(product => product.name));
  };

  // const handleFieldUpdate = (field, value) => {
  //   console.log(`Updating field: ${field} with value: ${value}`);
  //   setUpdatedFields(prev => {
  //     if (value !== lead[field]) {
  //       return { ...prev, [field]: value };
  //     } else {
  //       const { [field]: _, ...rest } = prev;
  //       return rest;
  //     }
  //   });
  // };

  const handleFieldUpdate = (field, value) => {
    if (field === 'lead_status' && value === 'Qualified') {
      setIsQualificationModalOpen(true);
    } else {
      console.log(`Updating field: ${field} with value: ${value}`);
      setUpdatedFields(prev => {
        if (value !== lead[field]) {
          return { ...prev, [field]: value };
        } else {
          const { [field]: _, ...rest } = prev;
          return rest;
        }
      });
    }
  };

  const handleQualificationConfirm = async () => {
    setIsQualificationModalOpen(false);
    setIsLoading(true);
    try {
      const payload = { ...updatedFields, lead_status: 'Qualified' };
      const response = await axiosInstance.put(`/leads/${lead.id}`, payload);
      
      // Create deal, account, and contact
      // await createDealAccountContact(lead.id);
      const newDealId = await createDealAccountContact(lead.id);

      fetchLeadData();
      setActiveStep(getStepIndex('Qualified'));
      setUpdatedFields({});
      setSnackbar({
        open: true,
        message: 'Lead qualified successfully! Deal, Account, and Contact created.',
        severity: 'success',
      });

      navigate(`/deals/detail/${newDealId}`);
    } catch (error) {
      console.error('Error qualifying lead:', error);
      setSnackbar({
        open: true,
        message: 'Error qualifying lead. Please try again.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createDealAccountContact = async (leadId) => {
    try {
      // Create account
      const accountResponse = await axiosInstance.post('/accounts/', {
        name: lead.company_name,
        // Add other account details as needed
      });

      // Create contact
      const contactResponse = await axiosInstance.post('/contacts/', {
        first_name: lead.first_name,
        last_name: lead.last_name,
        email: lead.email,
        phone: lead.phone,
        account_id: accountResponse.data.id,
        // Add other contact details as needed
      });

      // Create deal
      const dealResponse = await axiosInstance.post('/deals/', {
        name: `Deal for ${lead.company_name}`,
        account_id: accountResponse.data.id,
        contact_id: contactResponse.data.id,
        lead_id: leadId,
        user_id: user.id,
        stage: qualificationData.stage,
        value: parseFloat(qualificationData.value),
        probability: parseInt(qualificationData.probability),
        // Add other deal details as needed
      });

      console.log('Deal, Account, and Contact created successfully');
      return dealResponse.data.id;
    } catch (error) {
      console.error('Error creating deal, account, or contact:', error);
      throw error;
    }
  };

  const renderQualificationModal = () => (
    // <Modal
    //   open={isQualificationModalOpen}
    //   onClose={() => setIsQualificationModalOpen(false)}
    //   aria-labelledby="qualification-modal-title"
    //   aria-describedby="qualification-modal-description"
    // >
    //   <Box sx={{
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 400,
    //     bgcolor: 'background.paper',
    //     boxShadow: 24,
    //     p: 4,
    //   }}>
    //     <MDTypography id="qualification-modal-title" variant="h6" component="h2" mb={2}>
    //       Confirm Lead Qualification
    //     </MDTypography>
    //     <MDTypography id="qualification-modal-description" mb={4}>
    //       Are you sure you want to mark this lead as qualified? This action will create a new deal, account, and contact.
    //     </MDTypography>
    //     <MDBox display="flex" justifyContent="flex-end">
    //       <MDButton onClick={() => setIsQualificationModalOpen(false)} color="secondary" sx={{ marginRight: 2 }}>
    //         Cancel
    //       </MDButton>
    //       <MDButton onClick={handleQualificationConfirm} variant="contained" color="info">
    //         Confirm
    //       </MDButton>
    //     </MDBox>
    //   </Box>
    // </Modal>

    <Modal
      open={isQualificationModalOpen}
      onClose={() => setIsQualificationModalOpen(false)}
      aria-labelledby="qualification-modal-title"
      aria-describedby="qualification-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <MDTypography id="qualification-modal-title" variant="h6" component="h2" mb={2}>
          Confirm Lead Qualification
        </MDTypography>
        <MDTypography id="qualification-modal-description" mb={4}>
          Please provide the following information to create a deal for this qualified lead:
        </MDTypography>
        <TextField
          fullWidth
          label="Deal Value"
          name="value"
          type="number"
          value={qualificationData.value}
          onChange={handleQualificationDataChange}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="deal-stage-label">Deal Stage</InputLabel>
          <Select
            labelId="deal-stage-label"
            id="deal-stage"
            name="stage"
            value={qualificationData.stage}
            label="Deal Stage"
            onChange={handleQualificationDataChange}
          >
            <MenuItem value="Qualification">Qualification</MenuItem>
            <MenuItem value="Needs Analysis">Needs Analysis</MenuItem>
            <MenuItem value="Proposal">Proposal</MenuItem>
            <MenuItem value="Negotiation">Negotiation</MenuItem>
            <MenuItem value="Closed Won">Closed Won</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Probability (%)"
          name="probability"
          type="number"
          value={qualificationData.probability}
          onChange={handleQualificationDataChange}
          margin="normal"
          InputProps={{ inputProps: { min: 0, max: 100 } }}
        />
        <MDBox display="flex" justifyContent="flex-end" mt={4}>
          <MDButton onClick={() => setIsQualificationModalOpen(false)} color="secondary" sx={{ marginRight: 2 }}>
            Cancel
          </MDButton>
          <MDButton onClick={handleQualificationConfirm} variant="contained" color="info">
            Confirm
          </MDButton>
        </MDBox>
      </Box>
    </Modal>
  );

  const handleSaveChanges = async () => {
    setIsLoading(true);
    console.log('saving changes');
    console.log(`Updated fields at handleSaveChanges:`, updatedFields);
    
    if (Object.keys(updatedFields).length > 0) {
      try {
        const payload = { ...updatedFields, };
        const response = await axiosInstance.put(`/leads/${lead.id}`, payload);
        // setLead(response.data);
        fetchLeadData();
        const newStep = getStepIndex(response.data.lead_status);
        console.log(`New step: ${newStep}`);
        
        setActiveStep(newStep);
        setUpdatedFields({});
        setSnackbar({
          open: true,
          message: 'Lead updated successfully!',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating lead:', error);
        setSnackbar({
          open: true,
          message: 'Error updating lead. Please try again.',
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    }
    setIsEditing(false);
  };

  // const renderSection = (title, fields) => (
  //   <MDBox 
  //     mb={3} 
  //     p={3} 
  //     borderRadius="lg" 
  //     sx={{ 
  //       backgroundColor: '#fffff',
  //       boxShadow: 3
  //     }}
  //   >
  //     <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
  //       <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
  //     </MDBox>
  //     <Grid container spacing={3}>
  //       {fields.map((item) => (
  //         <Grid item xs={12} md={6} key={item.field}>
  //           <InlineEditField
  //             label={item.label}
  //             // value={lead?.[item.field] ?? ''}
  //             value={item.field.includes('_at') ? formatDate(lead?.[item.field]) : lead?.[item.field] ?? ''}
  //             options={item.options ?? null}
  //             onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
  //             isEditing={isEditing}
  //             readOnly={item.readOnly ?? false}
  //           />
  //         </Grid>
  //       ))} 
  //     </Grid>
  //   </MDBox>
  // );

  const renderSection = (title, fields) => (
    <MDBox 
      mb={3} 
      p={3} 
      borderRadius="lg" 
      sx={{ 
        backgroundColor: '#fffff',
        boxShadow: 3
      }}
    >
      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">{title}</MDTypography>
      </MDBox>
      <Grid container spacing={3}>
        {fields.map((item) => (
          <Grid item xs={12} md={6} key={item.field}>
            <InlineEditField
              label={item.label}
              value={item.field.includes('_at') ? formatDate(lead?.[item.field]) : lead?.[item.field] ?? ''}
              options={item.options ?? null}
              onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
              isEditing={isEditing && !isLeadLocked}
              readOnly={(item.readOnly ?? false) || isLeadLocked}
            />
          </Grid>
        ))} 
      </Grid>
    </MDBox>
  );


  const fetchActivities = async () => {
    try {

      const response = await axiosInstance.get(`/leads/${id}/activities`);
      console.log('Fetched activities:', response.data);
      setActivities(response.data || []);

    } catch (error) {

      console.error('Error fetching activities:', error);
      alert('Error fetching activities');
      setActivities([]);
    }
  };
  
  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleOpenConvertModal = () => setIsConvertModalOpen(true);
  const handleCloseConvertModal = () => setIsConvertModalOpen(false);
  
  const handleDelete = async () => {
    setIsLoading(true);
    handleCloseDeleteModal();
    try {
      
      await axiosInstance.delete(`/leads/${lead.id}`, {
        params: { delete_related: deleteRelated }
      });

      navigate('/leads');
    } catch (error) {
      console.error('Error deleting lead:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLeadData = async () => {
    setIsNoteLoading(true);
    setIsLoading(true);
    try {
      
      const response = await axiosInstance.get(`/leads/${id}`);
      console.log('Fetched lead:', response.data);
      setLead(response.data);

      const fetched_Step = getStepIndex(response.data.lead_status);
      setActiveStep(fetched_Step? fetched_Step : 0);
    } catch (error) {
      console.error('Error fetching lead data:', error);
    }
    finally {
      setIsLoading(false);
      setIsNoteLoading(false);
    }
  };

  const getStepIndex = (status) => {
    return steps.indexOf(status);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenActivityModal = () => {
    setIsActivityModalOpen(true);
  };

  const handleCloseActivityModal = () => {
    setIsActivityModalOpen(false);
    setNewActivity({
      lead_id: id,
      type: '',
      description: '',
      date: new Date().toISOString().split('T')[0],
    });
  };

  const handleActivityInputChange = (e) => {
    setNewActivity({ ...newActivity, [e.target.name]: e.target.value });
  };

  const handleSaveActivity = async () => {
    setIsLoading(true);
    handleCloseActivityModal();
    try {
      
      const response = await axiosInstance.post(`/leads/${id}/activities`, newActivity, {});
      setActivities([...activities, response.data]);
      setSnackbar({
        open: true,
        message: 'Activity created successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error saving activity:', error);
      setSnackbar({
        open: true,
        message: 'Error saving activity',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    //   <MDBox pt={6} pb={3}>
    //     <Grid container spacing={3}>
    //       <Grid item xs={12} md={8}>
    //         <Card>
    //           <MDBox
    //             mx={2}
    //             mt={-3}
    //             py={3}
    //             px={2}
    //             variant="gradient"
    //             bgColor="info"
    //             borderRadius="lg"
    //             coloredShadow="info"
    //           >
    //             <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
    //               <MDTypography variant="h6" color="white">
    //                 Lead Details : {(lead.first_name && lead.last_name)
    //                               ?lead.first_name.charAt(0).toUpperCase() + 
    //                               lead.first_name.slice(1).toLowerCase() + ' ' + 
    //                               lead.last_name.charAt(0).toUpperCase() + 
    //                               lead.last_name.slice(1).toLowerCase()
    //                               :"N/A"
    //                             }
    //                              {!isEditing && (
    //                               <Edit
    //                                 size={20}
    //                                 color="#ffffff"
    //                                 style={{ marginLeft: '10px', cursor: 'pointer' }}
    //                                 onClick={handleEditToggle}
    //                               />

    //                             )}
    //                             {isEditing && ( 
    //                               < >
    //                               <Save
    //                                 size={20}
    //                                 color="#ffffff"
    //                                 style={{ marginLeft: '10px', cursor: 'pointer' }}
    //                                 onClick={handleSaveChanges}
    //                               />
    //                             <SquareX
    //                             size={20}
    //                             color="#ffffff"
    //                             style={{ marginLeft: '10px', cursor: 'pointer' }}
    //                             onClick={handleSaveChanges}
    //                             />
    //                              </>
    //                             )}

    //               </MDTypography>

    //               <MDBox>
    //                 {/* <MDButton variant="contained" color="error" sx={{ mr: 1 }} onClick={handleOpenDeleteModal}>
    //                   Delete
    //                 </MDButton> */}
    //                 <DeleteButton onClick={handleOpenDeleteModal} />
    //               </MDBox>
    //             </MDBox>

    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <MDTypography variant="h6" color="white">
                    Lead Details : {(lead.first_name && lead.last_name)
                      ? lead.first_name.charAt(0).toUpperCase() + 
                        lead.first_name.slice(1).toLowerCase() + ' ' + 
                        lead.last_name.charAt(0).toUpperCase() + 
                        lead.last_name.slice(1).toLowerCase()
                      : "N/A"
                    }
                    {!isEditing && !isLeadLocked && (
                      <Edit
                        size={20}
                        color="#ffffff"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                        onClick={handleEditToggle}
                      />
                    )}
                    {isEditing && !isLeadLocked && ( 
                      <>
                        <Save
                          size={20}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={handleSaveChanges}
                        />
                        <SquareX
                          size={20}
                          color="#ffffff"
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                          onClick={() => setIsEditing(false)}
                        />
                      </>
                    )}
                  </MDTypography>

                  <MDBox>
                    <MDButton
                      variant="contained"
                      color="success"
                      startIcon={<Copy />}
                      onClick={handleCloneLead}
                      sx={{ mr: 1 }}
                    >
                      Clone Lead
                    </MDButton>
                    <DeleteButton onClick={handleOpenDeleteModal} />
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDTypography variant="button" color="white" mb={2}>
                    {lead.status}
                  </MDTypography>
                  <ThemedCustomStepper activeStep={activeStep} steps={leadStatus} />
                </MDBox>
              </MDBox>
              <MDBox p={3}>

                  {!isLoading ? (
                  <>
                    {renderSection("Basic Information", [
                      { label: "First Name", field: "first_name" },
                      { label: "Last Name", field: "last_name" },
                      { label: "Title", field: "title" },
                      { label: "Email", field: "email" },
                      { label: "Phone", field: "phone" },
                      { label: "Mobile", field: "mobile" },
                      { label: "Company", field: "company_name" },
                      { label: "Website", field: "website" },
                      { label: "Industry", field: "industry", options: ["Advertising", "Banking", "Consulting", "Education", "Finance", "Government", "Healthcare", "Insurance", "Manufacturing", "Media", "Retail", "Technology", "Telecommunications", "Travel", "Other"] },
                      { label: "Interested Products", field: "interested_products" },
                    ])}

                    {renderSection("Additional Details", [
                      { label: "Address", field: "address" },
                      { label: "City", field: "city" },
                      { label: "State", field: "state" },
                      { label: "Country", field: "country" },
                      { label: "Postal Code", field: "postal_code" },
                      { label: "Annual Revenue", field: "annual_revenue" },
                      { label: "Currency", field: "currency", options: ["USD", "EUR", "GBP", "INR", "JPY", "CAD", "AUD", "CHF", "CNY", "HKD"] },
                      { label: "Number of Employees", field: "number_of_employees" },
                    ])}

                    {renderSection("Lead Status", [
                      { label: "Lead Source", field: "lead_source" },
                      { label: "Lead Status", field: "lead_status", options: ["New", "In Progress", "Qualified", "Disqualified"] },
                      { label: "Lead Rating", field: "lead_rating", options: ["Cold", "Warm", "Hot"] },
                      { label: "Assigned To", field: "assigned_to_name", readOnly: true },
                      { label: "Created By", field: "created_by_name", readOnly: true },
                      { label: "Modified By", field: "updated_by_name", readOnly: true },
                      { label: "Created Date", field: "created_at", readOnly: true },
                      { label: "Last Activity", field: "updated_at", readOnly: true },
                    ])}
                  </>
                ) : (
                  <MDBox display="flex" justifyContent="center">
                    <CircularProgress />
                  </MDBox>
                )}

                <Divider sx={{ my: 3 }} />
                { !isNoteLoading ? (
                <MDBox>
                <MDTypography variant="h6" fontWeight="medium">Notes</MDTypography>
                  {[{label: "Notes", field: "notes"}]
                  .map((item) => (
                    <MDBox key={item.field} mt={2}>
                      <InlineEditField
                        label={item.label}
                        value={lead?.[item.field] ?? ''}
                        onSave={(newValue) => handleFieldUpdate(item.field, newValue)}
                        editText="edit"
                        editTextStyle={{ fontSize: '0.8rem', marginLeft: '8px', color: 'primary.main', cursor: 'pointer' }}
                      />
                    </MDBox>
                  ))}
                </MDBox>
                ) : (
                  <MDBox display="flex" justifyContent="center">
                    <CircularProgress />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Activities
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={handleOpenActivityModal}>
                  Add Activity
                </MDButton>
              </MDBox>
              <MDBox p={2}>
                <Activities activities={activities.filter(Boolean)} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Activity Modal */}
      <Modal
        open={isActivityModalOpen}
        onClose={handleCloseActivityModal}
        aria-labelledby="activity-modal-title"
        aria-describedby="activity-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <MDTypography id="activity-modal-title" variant="h6" component="h2" mb={2}>
            Add New Activity
          </MDTypography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="activity-type-label">Activity Type</InputLabel>
            <Select
              labelId="activity-type-label"
              id="activity-type"
              name="type"
              value={newActivity.type}
              label="Activity Type"
              onChange={handleActivityInputChange}
              style={{
                padding: '10px', // Add padding for better height
              }}
            >
              <MenuItem value="Call">Call</MenuItem>
              <MenuItem value="Meeting">Meeting</MenuItem>
              <MenuItem value="Email">Email</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={newActivity.description}
            onChange={handleActivityInputChange}
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            label="Date"
            name="date"
            type="date"
            value={newActivity.date ? newActivity.date.toString().split('T')[0] : ''}
            onChange={handleActivityInputChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <MDBox mt={4} mb={1} display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseActivityModal} color="secondary" sx={{ marginRight: 2 }}>
              Cancel
            </MDButton>
            <MDButton onClick={handleSaveActivity} variant="gradient" color="info">
              Save Activity
            </MDButton>
          </MDBox>
        </Box>
      </Modal>

      {/* Delete Confirmation Modal */}
        <Modal
          open={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
            <MDTypography id="delete-modal-title" variant="h6" component="h2" mb={2}>
              Confirm Deletion
            </MDTypography>
            <MDTypography id="delete-modal-description" mb={4}>
              Are you sure you want to delete this lead? This action cannot be undone.
            </MDTypography>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={deleteRelated}
                    onChange={(e) => setDeleteRelated(e.target.checked)}
                    color="primary"
                  />
                }
                label="Delete related records"
              />
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton onClick={handleCloseDeleteModal} color="secondary" sx={{ marginRight: 2 }}>
                Cancel
              </MDButton>
              <MDButton onClick={handleDelete} variant="contained" color="error">
                Delete
              </MDButton>
            </MDBox>
          </Box>
        </Modal>
        
      <ConvertLeadModal
        isOpen={isConvertModalOpen}
        onClose={handleCloseConvertModal}
        opportunityData={opportunityData}
        setOpportunityData={setOpportunityData}
        leadId={id}
      />

      <Footer />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {renderQualificationModal()}

    </DashboardLayout>
  );
}

export default LeadDetail;
